.slide-size {
  height: 390px;
}

.image-size {
  width: 100%;
}

.react-slideshow-container {
  z-index: -1;
}
