.polaroid {
  padding: 10px 5px 20px 5px;
  border: 1px solid #e4e4e4;
  background-color: white;
  box-shadow: 2px 2px 5px #aaaaaa;
  margin: 5px !important;
}

.dot-green {
  height: 12px;
  width: 12px;
  background-color: rgb(89, 193, 109);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dot-yellow {
  height: 12px;
  width: 12px;
  background-color: rgb(250, 247, 76);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
